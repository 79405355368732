import React, { useState } from 'react';
import styles from './InfluencerCard.module.css';
import { RWebShare } from 'react-web-share';
import useLocalStorage from "../../hooks/useLocalStorage";

const InfluencerCard = ({channelDetails}) => {
    const [isFollowed, setIsFollowed] = useLocalStorage(`${channelDetails.id}-follow`,false);

    const handleFollowClick = () => {
        setIsFollowed(!isFollowed);
    };

    return (
        <div className={styles.cardContainer}>
            <h2 className={styles.cardTitle}>{channelDetails.title}</h2>
            <div className={styles.infoContainer}>
                <img
                    src={channelDetails.img}
                    alt="Profile"
                    className={styles.profileImg}
                />
                <div>
                    <h3 className={styles.influencerName}>{channelDetails.name}</h3>
                    <p className={styles.programDescription}>{channelDetails.programDescription}</p>
                </div>
                <button
                    className={styles.followButton}
                    onClick={handleFollowClick}
                >
                    {isFollowed ? 'Followed' : '+ Follow'}
                </button>
                <RWebShare
                    data={{
                        title: channelDetails.name, // The title of the document you want to share.
                        text: `Check out these ${channelDetails.title}`, // A text to be shared.
                        url: window.location.href, // A URL or an array of URLs to be shared.
                    }}
                    onClick={() => console.log('Share button clicked!')}

                >
                    <button className={styles.shareButton}>
                        {/*<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"*/}
                        {/*     xmlns="http://www.w3.org/2000/svg">*/}
                        {/*    <path fill-rule="evenodd" clip-rule="evenodd"*/}
                        {/*          d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z"*/}
                        {/*          fill="black"/>*/}
                        {/*</svg>*/}

                        <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9 12C9 13.3807 7.88071 14.5 6.5 14.5C5.11929 14.5 4 13.3807 4 12C4 10.6193 5.11929 9.5 6.5 9.5C7.88071 9.5 9 10.6193 9 12Z"
                                stroke="#7c7c7c" stroke-width="1.5"/>
                            <path d="M14 6.5L9 10" stroke="#7c7c7c" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M14 17.5L9 14" stroke="#7c7c7c" stroke-width="1.5" stroke-linecap="round"/>
                            <path
                                d="M19 18.5C19 19.8807 17.8807 21 16.5 21C15.1193 21 14 19.8807 14 18.5C14 17.1193 15.1193 16 16.5 16C17.8807 16 19 17.1193 19 18.5Z"
                                stroke="#7c7c7c" stroke-width="1.5"/>
                            <path
                                d="M19 5.5C19 6.88071 17.8807 8 16.5 8C15.1193 8 14 6.88071 14 5.5C14 4.11929 15.1193 3 16.5 3C17.8807 3 19 4.11929 19 5.5Z"
                                stroke="#7c7c7c" stroke-width="1.5"/>
                        </svg>
                    </button>
                </RWebShare>
            </div>
        </div>
    );
};

export default InfluencerCard;