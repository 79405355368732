import React, {useEffect, useRef, useState} from 'react';
import ShoppableWidget from "../shoppableWidget/ShoppableWidget";

// Styles
import './VideoPlayer.css';

const VideoPlayer = ({
                       usernameRaisedHand,
                       showRaiseHandPopup,
                       playbackUrl,
                       setCurrentProductId,
                       fallbackImg
                     }) => {
  const playerRef = useRef(null); // Ref to store the player instance
  const [activeProductId, setActiveProductId] = useState(null);
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const [isLive, setIsLive]= useState(false);
  useEffect(() => {
    if (!window.IVSPlayer.isPlayerSupported) {
      console.warn('The current browser does not support the Amazon IVS player.');
      return;
    }

    const MediaPlayerPackage = window.IVSPlayer;
    const PlayerState = MediaPlayerPackage.PlayerState;
    const PlayerEventType = MediaPlayerPackage.PlayerEventType;

    // Initialize player if it's not already initialized
    if (!playerRef.current) {
      playerRef.current = MediaPlayerPackage.create();
      playerRef.current.attachHTMLVideoElement(document.getElementById('video-player'));

      // Add event listeners
      playerRef.current.addEventListener(PlayerState.PLAYING, () => setIsPlayerLoaded(true));
      playerRef.current.addEventListener(PlayerState.ENDED, () => console.info('Player State - ENDED'));
      playerRef.current.addEventListener(PlayerState.READY, () => console.info('Player State - READY'));
      playerRef.current.addEventListener(PlayerEventType.ERROR, (err) => {
        console.warn('Player Event - ERROR:', err);
        setIsLive(false)
      });

      playerRef.current.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
        console.log('Timed metadata: ', cue.text,cue);
        const metadataText = JSON.parse(cue.text);
        const productId = metadataText['productId'];
        setCurrentProductId(productId)
        setActiveProductId(productId)

      });
    }

    // Cleanup function
    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener(PlayerState.PLAYING, () => setIsPlayerLoaded(true));
        playerRef.current.removeEventListener(PlayerState.ENDED, () => console.info('Player State - ENDED'));
        playerRef.current.removeEventListener(PlayerState.READY, () => console.info('Player State - READY'));
        playerRef.current.removeEventListener(PlayerEventType.ERROR, (err) => console.warn('Player Event - ERROR:', err));
        playerRef.current.delete();
        playerRef.current = null;
      }
      setIsPlayerLoaded(false)
    };
  }, []); // This runs only once on mount

  useEffect(() => {
    setActiveProductId("");
    setIsLive(true)
    // Load new playback URL when it changes
    if (playerRef.current) {
      playerRef.current.load(playbackUrl);
      setIsPlayerLoaded(false);
      playerRef.current.play(); // Optionally, start playback automatically
    }
  }, [playbackUrl]); // This runs every time playbackUrl changes

  return (
      <>
        <div className='player-wrapper'>
          <div className='aspect-169 pos-relative full-width'>
            <video
                id='video-player'
                className='video-elem pos-absolute full-width'
                playsInline
                muted
            ></video>
            <div className='player-overlay pos-absolute'>
              {showRaiseHandPopup && (
                  <div className='overlay-raise-hand'>
                    {usernameRaisedHand} raised their hand
                  </div>
              )}
              { !isPlayerLoaded && fallbackImg &&
                <img src={fallbackImg || "/images/not-live.png"}/>
              }
              <ShoppableWidget productId={activeProductId} />
            </div>
          </div>
        </div>
      </>
  );
};

export default VideoPlayer;