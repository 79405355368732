// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

export const AVATARS = [
  {
    name: "bear",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bear.png",
  },
  {
    name: "bird",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bird.png",
  },
  {
    name: "bird2",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/bird2.png",
  },
  {
    name: "dog",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/dog.png",
  },
  {
    name: "giraffe",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/giraffe.png",
  },
  {
    name: "hedgehog",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/hedgehog.png",
  },
  {
    name: "hippo",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/animals_square/hippo.png",
  }
];

export const STICKERS = [
  {
    name: "cute",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-1.png"
  },
  {
    name: "angry",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-2.png"
  },
  {
    name: "sad",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-3.png"
  },
  {
    name: "happy",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-4.png"
  },
  {
    name: "surprised",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-5.png"
  },
  {
    name: "cool",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-6.png"
  },
  {
    name: "love",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-7.png"
  },
  {
    name: "rocket",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-8.png"
  },
  {
    name: "confetti",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-9.png"
  },
  {
    name: "camera",
    src: "https://d39ii5l128t5ul.cloudfront.net/assets/chat/v1/sticker-10.png"
  } 
]

export const featuredCreators= {
  "001": {
    id: "001",


    name: "Ankush Das",
    img: "/images/Ankush_dp.png",
    playback_url:
        "https://86a9604d8d53.ap-south-1.playback.live-video.net/api/video/v1/ap-south-1.701980022429.channel.lyWBoxgzVr93.m3u8",
    isLive:true,
    channelArn:"arn:aws:ivs:ap-south-1:701980022429:channel/lyWBoxgzVr93",
    title:"Smart Watches at affordable costs",
    programDescription:"Fashion Influencer Program",
    chatRoomId:"ar0v9EHJMOJq",
    videoThumbnail:"/images/Thumbnails_Pulse_2.jpg"
  },
  "002": {
    id: "002",
    name: "Siddarth Khanna",
    img: "/images/Siddharth_dp.png",
    playback_url:
        "https://86a9604d8d53.ap-south-1.playback.live-video.net/api/video/v1/ap-south-1.701980022429.channel.dUhxO0TdP3JE.m3u8",
    title:"Dive into the World of Headphones 🎧",
    programDescription:"Tech Influencer Program",
    chatRoomId:"cEhystoEnkrV",
    isLive: true,
    channelArn:"arn:aws:ivs:ap-south-1:701980022429:channel/dUhxO0TdP3JE",
    videoThumbnail: "/images/Thumbnails_Pulse_5.jpg"
  },
  "003": {
    id: "003",
    name: "Aman Mehta",
    img: "/images/Aman_Dp.png",
    playback_url:
        "https://760b256a3da8.us-east-1.playback.live-video.net/api/video/v1/us-east-1.049054135175.channel.6tM2Z9kY16nH.m3u8",
    title:"Best Smart TV for your home",
    programDescription:"Tech Influencer Program",
    chatRoomId:"Sha4PBUUgOgB"
  },
  "004": {
    id: "004",
    name: "Sneha",
    img: "/images/Sneha_dp.png",
    playback_url:
        "https://4da4a22026d3.us-west-2.playback.live-video.net/api/video/v1/us-west-2.298083573632.channel.hdviye1zVPxT.m3u8",
    title:"Everyday Grooming Essentials That You Must Have At Home",
    programDescription:"Fashion Influencer",
    chatRoomId:"JZXZSf29klLv"
  },
}


export const products=[
  {
    "id": "1200367895",
    "name": "Noise ColorFit Pro 4 Smart Watch",
    "imageUrl": "https://www.bajajmall.in/emistore/media/catalog/product/_/w/_wrbswcolorfitpro4stdblkblk_base.jpeg",
    "imageLargeUrl": "https://www.bajajmall.in/emistore/media/catalog/product/_/w/_wrbswcolorfitpro4stdblkblk_base.jpeg",
    "price": 5999,
    "discountedPrice": 3000,
    "shortDescription": "1.72\" TruView Display Smart Watch with Bluetooth Calling, Fully Functional Digital Crown - Charcoal Black",
    "features": [
      "1.72\" TruView Display",
      "Bluetooth Calling",
      "Fully Functional Digital Crown"
    ],
    "link": "https://www.bajajmall.in/emi-store/noise-colorfit-pro-4-bluetooth-calling-smart-watch-with-1-72-inch-truview-display-fully-functional-digital-crown-charcoal-black-onesize.html"
  },
  {
        "id": "1001267821",
        "name": "Fastrack FS1 Pro Smartwatch",
        "imageUrl": "https://m.media-amazon.com/images/I/61Rs9z0qRmL._SX679_.jpg",
        "imageLargeUrl": null,
        "price": 2699,
        "discountedPrice": 750,
        "shortDescription": "1.96 Super AMOLED Arched Display with High Resolution of 410X502, Singlesync BT Calling, Nitrofast Charging, 110+ Sports Modes, 200+ Watchfaces",
        "features": [
          "1.96 Super AMOLED Arched Display with High Resolution of 410X502",
        "Singlesync BT Calling",
        "Nitrofast Charging",
        "110+ Sports Modes",
        "200+ Watchfaces"
    ],
    "link": "https://www.bajajmall.in/emi-store/search.html?q=smart+watch"
},
  {
    "id": "100027896",
    "name": "boAt Storm Call Smart Watch",
    "imageUrl": "https://www.bajajmall.in/emistore/media/catalog/product/s/t/stormcallpitchblack_base.jpeg",
    "imageLargeUrl": "https://www.bajajmall.in/emi-store/boat-storm-call-bluetooth-calling-smart-watch-lg.jpg",
    "price": 7990,
    "discountedPrice": 4499,
    "shortDescription": "1.69 inch HD display Smartwatch in Pitch Black",
    "features": [
      "1.69 inch HD display",
      "Bluetooth Calling"
    ],
    "link": "https://www.bajajmall.in/emi-store/boat-storm-call-bluetooth-calling-smart-watch-with-169-inch-hd-display-smartwatch-pitch-black.html"
  },
  {
    "id": "10005327891",
    "name": "Fastrack FS1 Pro Smartwatch",
    "imageUrl": "https://m.media-amazon.com/images/I/61Rs9z0qRmL._SX679_.jpg",
    "imageLargeUrl": "https://www.bajajmall.in/emi-store/fastrack-fs1-pro-smartwatch-lg.jpg",
    "price": 7995,
    "discountedPrice": 2699,
    "shortDescription": "1.96\" Super AMOLED Arched Display with High Resolution of 410X502, Singlesync BT Calling, Nitrofast Charging, 110+ Sports Modes, 200+ Watchfaces",
    "features": [
      "1.96\" Super AMOLED Arched Display with High Resolution of 410X502",
      "Singlesync BT Calling",
      "Nitrofast Charging",
      "110+ Sports Modes",
      "200+ Watchfaces"
    ],
    "link": "https://www.bajajmall.in/emi-store/fastrack-fs1-pro-smartwatch.html"
  },
  {
    "id": "1000567890",
    "name": "Eyeshadow Trio",
    "imageUrl": "/images/1000567890.jpg",
    "imageLargeUrl": "images/1000567890-lg.jpg",
    "price": 125,
    "discountedPrice": 99,
    "shortDescription": "By combining potent natural ingredients, ACME creates products that perform with experiences that transform.",
    "features": [
      "Long-lasting colors",
      "Variety of shades",
      "Blendable formula"
    ],
    "link": "https://www.bajajmall.in/emi-store/"
  },
  {
    "id": "1000567891",
    "name": "Waterproof Duo",
    "imageUrl": "/images/1000567891.jpg",
    "imageLargeUrl": "images/1000567891-lg.jpg",
    "price": 27,
    "discountedPrice": 25,
    "shortDescription": "Waterproof eyeliner and mascara for defined and long-lasting eye makeup.",
    "features": [
      "Waterproof formula",
      "Defines lashes and eyes",
      "Long-lasting",
      "No smudging"
    ],
    "link": "https://www.bajajmall.in/emi-store/"
  },
  {
    "id": "1000567892",
    "name": "Rose Lip Set",
    "imageUrl": "/images/1000567892.jpg",
    "imageLargeUrl": "images/1000567892-lg.jpg",
    "price": 35,
    "discountedPrice": 28,
    "shortDescription": "Satin rose lipstick set for moisturized lips with a lustrous shine.",
    "features": [
      "Satin finish",
      "Rose-inspired shades",
      "Moisturizing formula"
    ],
    "link": "https://www.bajajmall.in/emi-store/"
  },
  {
    "id": "1000567893",
    "name": "Lip Gloss Bomb",
    "imageUrl": "/images/1000567893.jpg",
    "imageLargeUrl": "images/1000567893-lg.jpg",
    "price": 19,
    "discountedPrice": 15,
    "shortDescription": "High-shine lip luminizer with a universal shade for a glamorous look.",
    "features": [
      "High shine",
      "Universal shade",
      "Non-sticky formula"
    ],
    "link": "https://www.bajajmall.in/emi-store/"
  },
  {
    "id": "1000567894",
    "name": "Daily Palette",
    "imageUrl": "/images/1000567894.jpg",
    "imageLargeUrl": "images/1000567894-lg.jpg",
    "price": 103,
    "discountedPrice": 95,
    "shortDescription": "Versatile palette with essential shades for daily makeup looks.",
    "features": [
      "Versatile palette",
      "Essential shades",
      "Matte and shimmer options"
    ],
    "link": "https://www.bajajmall.in/emi-store/"
  },
  {
    "id": "1000567895",
    "name": "Eye Sparkle Set",
    "imageUrl": "/images/1000567895.jpg",
    "imageLargeUrl": "images/1000567895-lg.jpg",
    "price": 59,
    "discountedPrice": 45,
    "shortDescription": "Eye care set for glossy eyes and enhanced lashes with sparkle effect.",
    "features": [
      "Glossy finish",
      "Eye-catching sparkle",
      "Enhances lashes"
    ],
    "link": "https://www.bajajmall.in/emi-store/"
  }
]
