import React from "react";
import { featuredCreators } from "../../constants";
import {Link} from "react-router-dom";

const FeaturedCreators = ({activeChannelId}) => {
    return (
        <>
            <h3 >Featured Creators</h3>
            {Object.values(featuredCreators).map(({ id, name, img }) => (
                <Link to={`/channel/${id}`} key={id} className={`featured-creator ${id===activeChannelId?"featured-creator-selected":""}`} style={{ textDecoration: 'none', color:"black" }}>
                    <img src={img} alt={name} className="featured-creator-img" />
                    <span>{name}</span>
                </Link>
            ))}
        </>
    );
};

export default FeaturedCreators;