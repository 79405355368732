import React, { useState } from 'react';
import "./AddProduct.css";

import { products, featuredCreators} from "../../constants";
import {API_URL} from "../../config";


function AddProduct() {
    const [selectedChannel, setSelectedChannel] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');

    const handleSubmit = () => {
        console.log(`Selected Channel: ${selectedChannel}, Selected Product: ${selectedProduct}`);
        // Here you can add your submit logic
        if(!selectedChannel || !selectedProduct) return;
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        const data = JSON.stringify({
            channelArn: selectedChannel,

            metadata: JSON.stringify( {
                productId: selectedProduct,
            })
        });
        const requestOptions = {
            headers: myHeaders,
            method: "POST",
            body: data,
        };

        fetch(`${API_URL}put-metadata`, requestOptions)
            .then((response) => response.text())
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    };

    return (
        <div className="add-product-container">
        <div className="add-product" style={{ padding: '20px', backgroundColor: '#f0f0f0' }}>
            <h2>Select Channel and Product</h2>
            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="channel-select">Channel:</label>
                <select
                    id="channel-select"
                    value={selectedChannel}
                    onChange={(e) => setSelectedChannel(e.target.value)}
                    style={{ marginLeft: '10px' }}
                >
                    <option value="">Select a channel</option>

                    {Object.values(featuredCreators).map(({name, isLive, channelArn,id}) => {
                        if(!isLive || !channelArn) return null;
                        return (<option key={id} value={channelArn}>{name}</option>)
                    })}
                </select>
            </div>

            <div style={{ marginBottom: '20px' }}>
                <label htmlFor="product-select">Product:</label>
                <select
                    id="product-select"
                    value={selectedProduct}
                    onChange={(e) => setSelectedProduct(e.target.value)}
                    style={{ marginLeft: '10px' }}
                >
                    <option value="">Select a product</option>
                    {products.map((product) => (
                        <option key={product.id} value={product.id}>{product.name}</option>
                    ))}
                </select>
            </div>

            <button onClick={handleSubmit} style={{ cursor: 'pointer' }}>
                Submit
            </button>
        </div>
        </div>
    );
}

export default AddProduct;