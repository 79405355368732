// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React from 'react';

import Chat from './chat/Chat';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import AddProduct from "./AddProduct/AddProduct";
const router = createBrowserRouter([
    {
        path: "/",
        element: (
                <Chat/>
        ),
    },
    {
        path: "/channel/:channelId",
        element: (
                <Chat/>
        ),
    },
    {
        path:"/add-product",
        element:(

<AddProduct/>
        )
    }
]);

function App() {
    return(
        <div className="App full-width full-height">
            <RouterProvider router={router} />
        </div>
        );
}

export default App;
