import React, {useMemo, useState} from 'react';
import { motion } from "framer-motion"
import "./ShoppableWidget.css"
import {products} from "../../constants";

const shoppableExpandedVariants = {
    open: {
        opacity: 1,
        y: 0,
        transition: {
            delayChildren: 5,
            staggerDirection: 1
        }
    },
    closed: { opacity: 0,pointerEvents:"none", y: "-100%" },
}
const shoppableVariants = {
    open:{opacity:0,pointerEvents:"none", y:"-100%"},
    closed:{opacity:1, y:0}
}


const ShoppableWidget=({productId})=>{
    const [isShoppableOpen, setIsShoppableOpen] = useState(false);

    console.log(productId);
    const productDetails = products.find(product => product.id === productId);

    if(!productDetails){
        if(isShoppableOpen){
            setIsShoppableOpen(false);
        }
        return null;
    }
    console.log(productDetails);
    const onSale = (productDetails.price !== productDetails.discountedPrice) ? 'product-onsale' : '';

    return (
        <div className='overlay-shoppable-video' >
            <motion.div animate={isShoppableOpen ? "open" : "closed"} variants={shoppableExpandedVariants}
                        className='shoppable-container-expanded'>
                <div className="product-details">
                    <img src={productDetails.imageUrl} alt="product"
                    />
                    <div className="product-description-price">
                        <div className="product-name">{productDetails.name}</div>


                        <div className={`product-price-wrapper ${onSale}`}>
                            <span className="product-price">&#x20B9;{`${productDetails.price}`}</span>
                            {onSale && (
                                <span className="product-discounted-price">&#x20B9;{`${productDetails.discountedPrice}`}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className='product-description'>
                    {productDetails.shortDescription}
                </div>
                <ul className="product-features">
                    <div className="product-feature-title">More features</div>
                    {
                        productDetails.features?.map((feature, index) => [
                            <li key={index}>{feature}</li>
                        ])
                    }

                </ul>


                <div className="shoppable-container-buttons">
                    <button className="cancel-button"
                            onClick={() => setIsShoppableOpen(isShoppableOpen => !isShoppableOpen)}>Cancel
                    </button>
                    <button className="buy-now-button" onClick={
                        (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            window.open(productDetails.link, "_blank")

                        }
                    }>Buy Now
                    </button>
                </div>
            </motion.div>


            <motion.div animate={isShoppableOpen ? "open" : "closed"} variants={shoppableVariants}
                        className='shoppable-container'
                        onClick={() => setIsShoppableOpen(isShoppableOpen => !isShoppableOpen)}>
                <img src={productDetails.imageUrl} alt="product"/>
                <div className="product-details">
                    <div className="product-name">{productDetails.name}
                        <div className="know-more">Know More</div>
                    </div>


                    <div className="buy-now-container">
                        <div className={`product-price-wrapper ${onSale}`}>
                            <span className="product-price">&#x20B9;{`${productDetails.price}`}</span>
                            {onSale && (
                                <span
                                    className="product-discounted-price">&#x20B9;{`${productDetails.discountedPrice}`}</span>
                            )}
                        </div>
                        <button className="buy-now-button"
                                onClick={
                                    (e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        window.open(productDetails.link, "_blank")

                                    }
                                }
                        >Buy Now
                        </button>
                    </div>
                </div>
            </motion.div>
        </div>


    )
}

export default ShoppableWidget;