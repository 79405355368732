import React, {useCallback} from 'react';
import CardComponent from './CardComponent';
import useEmblaCarousel from 'embla-carousel-react'
import "./UpcomingLiveStreamCarousel.css"

const cardsData = [
    {
        imageSrc: '/images/Thumbnails_Pulse_5.jpg',
        title: 'Best Headphones and earphones for every range',
        user: 'Siddarth Khanna',
        timeStamp: '1 hour ago',
        live: false,
    },
    {
        imageSrc: '/images/Thumbnails_Pulse_4.jpg',
        title: "Unique Kitchen finds that are must have",
        user: 'Sneha',
        timeStamp: 'LIVE',
        live: true,
    },
    {
        imageSrc: '/images/Thumbnails_Pulse_3.jpg',
        title: 'Best Smart TV for your home',
        user: 'Ankush Das',
        timeStamp: 'Sat: 12 May, 12:00pm',
        live: false,
    },
    {
        imageSrc: '/images/Thumbnails_Pulse_2.jpg',
        title: 'Smart Watches at affordable costs',
        user: 'Ankush Das',
        timeStamp: 'Thu: 2 May, 12:00pm',
        live: false,
    },
];

function UpcomingLiveStreamCarousel() {
    const [emblaRef, emblaApi] = useEmblaCarousel()

    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev()
    }, [emblaApi])

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext()
    }, [emblaApi])
    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {cardsData.map((card, index) =>
                        <CardComponent card={card} key={index}/>
                    )}
                </div>
            </div>
            <button className="embla__prev" onClick={scrollPrev}>
                <img src='https://www.bajajmall.in/content/dam/emistoremarketplace/index/pdpproductgallery/arrow.png' />
            </button>
            <button className="embla__next" onClick={scrollNext}>
                <img src='https://www.bajajmall.in/content/dam/emistoremarketplace/index/pdpproductgallery/arrow.png' />
            </button>
        </div>
    );
}

export default UpcomingLiveStreamCarousel;