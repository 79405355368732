import { useState, useEffect } from 'react';

function useLocalStorage(key, initialValue = undefined) {
    // Create state to store the value
    const [value, setValue] = useState(() => {
        // Retrieve the initial value from localStorage if available
        const storedValue = localStorage.getItem(key);
        // Parse the stored JSON value or use the initial value
        return storedValue ? JSON.parse(storedValue) : initialValue;
    });

    // Use useEffect to update state when the key changes
    useEffect(() => {
        // Check for a stored value when the key changes
        const storedValue = localStorage.getItem(key);
        // Parse the stored JSON value or use the initial value
        setValue(storedValue ? JSON.parse(storedValue) : initialValue);
    }, [key]); // Only re-run the effect if the key changes

    // Define a setter function that updates both state and localStorage
    const set = (newValue) => {
        setValue(newValue);
        localStorage.setItem(key, JSON.stringify(newValue));
    };

    // Return the value and setter function
    return [value, set];
}

export default useLocalStorage;