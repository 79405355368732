import React from 'react';
import styles from './CardComponent.module.css';

const CardComponent = ({ card }) => {
    return (
                <div className={`${styles.card} embla__slide`}>
                    <div className={styles.cardHeader}>
                        {card.live?(
                            <span className={styles.liveBadge}>LIVE</span>
                        ): (<span className={styles.timeStamp}>{card.timeStamp}</span>
                        )}
                    </div>
                    <img className={styles.cardImage} src={card.imageSrc} alt={card.title} />
                    <div className={styles.cardBody}>
                        <h3 className={styles.cardTitle}>{card.title}</h3>
                        <p className={styles.cardUser}>{card.user}</p>
                    </div>
                </div>

    );
};

export default CardComponent;